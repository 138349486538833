import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { divvyTimezone, locationMap } from '../transactions-page-helpers';
import { TransactionsDateFiltersParams } from '../transactions-page-params';

/**
 * We use utc/timezone functionality to set a static timezone for statement periods, etc.
 */
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Converts unix timestamp to formatted date (e.g. 6 Mar 2024)
 * @param unixTime unix timestamp we are converting
 */
const convertUnixToDate = (unixTime: number) => dayjs.unix(unixTime).tz(divvyTimezone).format('D MMM YYYY');

/**
 * Converts unix timestamp array statement period into a readable string
 * @param period unix timestamp range
 */
const getStatementPeriodLabel = (period: [number, number]) => {
  const [startUnix, endUnix] = period;
  return `${convertUnixToDate(startUnix)} - ${convertUnixToDate(endUnix)}`;
};

/**
 * Converts date range string back into unix timestamp range.
 * @param label date range string
 */
const getPeriodFromLabel = (label: string): [number, number] => {
  const [start, end] = label.split('-');
  return [dayjs(start).unix(), dayjs(end).unix()];
};

/**
 * Date filters for Transactions Datagrid.
 * @param dateRange the current date range of transactions
 * @param selectedStatementPeriod the current selected statement period
 * @param updateSelectedStatementPeriod setter for statement period
 * @param updateDateRange setter for date range
 * @param location basically transaction category (e.g. divvy or ramp)
 */
// eslint-disable-next-line import/prefer-default-export
export const TransactionsDateFilters = function ({
  dateRange,
  selectedStatementPeriod,
  updateSelectedStatementPeriod,
  updateDateRange,
  location,
}: TransactionsDateFiltersParams) {
  const [statementPeriods, setStatementPeriods] = useState<[number, number][]>([]);
  const [statementPeriodStart, statementPeriodEnd] = selectedStatementPeriod;

  // calculates statement periods on component load
  useEffect(() => {
    const periods = locationMap[location].getStatementPeriods();
    setStatementPeriods(periods);
    // we want to default the selected statement period to the most recent (periods are descending)
    updateSelectedStatementPeriod(periods[0]);
  }, []);

  // formats unix timestamp statement periods as readable dates
  const formattedStatementPeriods = statementPeriods.map((period) => getStatementPeriodLabel(period));

  return (
    <>
      {/*
        Statement period selector.
        Select component doesn't natively accept number[] as value, so we have to do some workarounds to convert and de-convert to/from string.
       */}
      <FormControl fullWidth className="mt-auto w-64">
        <InputLabel>Statement Period</InputLabel>
        <Select
          value={getStatementPeriodLabel(selectedStatementPeriod)}
          onChange={(e) => updateSelectedStatementPeriod(getPeriodFromLabel(e.target.value))}
          label="Statement Period"
        >
          {formattedStatementPeriods.map((period) => <MenuItem value={period}>{period}</MenuItem>)}
        </Select>
      </FormControl>
      {/* Fine-tune date range selector (allows user to select sub range within period) */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          value={dateRange}
          onChange={(range) => { updateDateRange(range); }}
          className="mt-10 w-1/3"
          minDate={dayjs.unix(statementPeriodStart).tz(divvyTimezone)}
          maxDate={dayjs.unix(statementPeriodEnd).tz(divvyTimezone)}
        />
      </LocalizationProvider>
    </>
  );
};
